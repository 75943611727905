import { releaseDetail } from "./release_details";

export const environment = {
    production: true,
    baseUrl: 'https://staging.my-masjid.com/api/',
    url: 'https://staging.my-masjid.com',
    controlPanelUrl: 'https://controlpanelstg.my-masjid.com/',
    refreshtimeout: '1800000',
    version: releaseDetail.version,
    releaseDate: releaseDetail.date,
};